<template>
  <Header
    :scrolling="scrolling"
  />
  <div style="min-height: 100vh;">
    <router-view></router-view>
  </div>
  <Footer />
  <Modals />
</template>

<script>
import scrollHandler from './mixins/scrollHandler';
import appInit from './mixins/appInit';
import Header from './components/Header';
import Modals from './components/Modals';
import Footer from './components/Footer';

export default {
  name: 'App',
  mixins: [scrollHandler, appInit],
  components: {
    Header,
    Modals,
    Footer,
  },
}
</script>

