import Home from '../views/Home.vue';

export default [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/create',
    name: 'Create',
    component: () => import(/* webpackChunkName: "inner" */ '../views/Create2'),
    meta: {
      applicationFlow: true,
    },
  },
  {
    path: '/create2',
    name: 'Create2',
    component: () => import(/* webpackChunkName: "inner" */ '../views/Create'),
    meta: {
      applicationFlow: true,
    },
  },
  {
    path: '/apply',
    name: 'Apply',
    component: () => import(/* webpackChunkName: "inner" */ '../views/Apply'),
    meta: {
      applicationFlow: true,
    },
  },
  {
    path: '/verifying',
    name: 'Verifying',
    component: () => import(/* webpackChunkName: "inner" */ '../views/Verifying'),
    meta: {
      applicationFlow: true,
    },
  },
  {
    path: '/congratulations',
    name: 'Congratulations',
    component: () => import(/* webpackChunkName: "inner" */ '../views/Congratulations'),
    meta: {
      applicationFlow: true,
    },
  },
  {
    path: '/summary',
    name: 'Summary',
    component: () => import(/* webpackChunkName: "inner" */ '../views/Summary'),
    meta: {
      applicationFlow: true,
    },
  },
  {
    path: '/approved',
    name: 'Approved',
    component: () => import(/* webpackChunkName: "inner" */ '../views/Approved'),
    meta: {
      applicationFlow: true,
    },
  },
  {
    path: '/thankyou',
    name: 'Thankyou',
    component: () => import(/* webpackChunkName: "inner" */ '../views/Thankyou'),
    meta: {
      applicationFlow: true,
    },
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "inner" */ '../views/About')
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import(/* webpackChunkName: "inner" */ '../views/FAQ')
  },
  {
    path: '/creditbasic',
    name: 'CreditBasic',
    component: () => import(/* webpackChunkName: "inner" */ '../views/CreditBasic')
  },
  {
    path: '/businessloans',
    name: 'BusinessLoans',
    component: () => import(/* webpackChunkName: "inner" */ '../views/BusinessLoans')
  },
  {
    path: '/process',
    name: 'Process',
    component: () => import(/* webpackChunkName: "inner" */ '../views/Process')
  },
]