<template>
  <div id="mobile-menu" class="align-items-center justify-content-center">
    <div class="container text-center">
      <ul class="mobile-nav">
        <li><router-link to="/#personal-loans" class="nav-trigger-default" @click="triggerMenu">Personal Loans</router-link></li>
        <li><router-link to="/process#start" @click="triggerMenu">The Process</router-link></li>
        <li><router-link to="/faq#start" @click="triggerMenu">FAQ</router-link></li>
        <li><router-link to="/about#start" @click="triggerMenu">About Us</router-link></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileMenu',
  methods: {
    triggerMenu() {
     this.$emit('itemClicked');
    },
  },
}
</script>
