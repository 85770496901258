export default {
  data() {
    return {
      scrolling: false,
    }
  },
  mounted() {
    if (window.scrollY >= 10) {
      this.scrolling = true;
    }

    window.addEventListener('scroll', () => {
      if (window.scrollY >= 10) {
        this.scrolling = true;
      } else {
        this.scrolling = false;
      }
    });
  },
};
