import { useStore } from 'vuex';
import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';
import VueScrollTo from 'vue-scrollto';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to) {
    if (to.hash) {
      VueScrollTo.scrollTo(to.hash, 700);
      return { selector: to.hash }
    }
    return { top: 0 }
  },
})

let firstEvent = false;

router.afterEach((to) => {
  const store = useStore();
  if(!firstEvent)
  {
    store.dispatch('registerAction', {
      name: to.name,
      value: to.fullPath,
      type: 'initPage',
    });
    firstEvent=true
  }


  store.dispatch('registerAction', {
    name: to.name,
    value: to.path,
    type: 'visit',
  });
});

export default router
