const appStorage = {
  getItem(key) {
    if (localStorage) {
      return localStorage.getItem(key);
    }
    return undefined;
  },
  setItem(key, value) {
    if (localStorage) {
      localStorage.setItem(key, value);
    }
  },
  removeItem(key) {
    if (localStorage) {
      localStorage.removeItem(key);
    }
  },
};

export default appStorage;
