import moment from 'moment';
import appStorage from '../../helpers/appStorage';
import { formatPhoneNumber } from '../../helpers/formatters';

export default {
  saveFormData: (state, data) => {
    state.formData = {
      ...state.formData,
      ...data,
    };
  },
  mergeVisitData: (state, data) => {
    Object.entries(data).forEach( ([k, v]) => {
      state.visitData[k] = v || state.visitData[k]
    })
  },
  saveSessionID: (state, data) => {
    state.visitData.sessionID = data.sessionID;
    // save to local storage
    const now = new Date();
    appStorage.setItem('session',
      JSON.stringify({
        subid1: data.subid1,
        subid2: data.subid2,
        subid3: data.subid3,
        subid4: data.subid4,
        sessionID: data.sessionID,
        webmasterID: data.webmasterID,
        subaccount: data.subaccount,
        expires: now.getTime() + (24 * 60 * 60 * 1000),
      }));
  },
  saveUserFromStorage: (state, data) => {
    state.formData = {
      ...state.formData,
      ...{
        id: data.id,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        dob: moment(data.dob).format('MM/DD/YYYY'),
        zip: data.zip,
        city: data.city,
        state: data.state,
        address: data.address,
        cellPhone: formatPhoneNumber(data.cellPhone?.toString() || ''), 
        ssn: data.ssn,
      }
    };
  },
}