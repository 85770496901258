<template>
  <div
    class="modal fade"
    id="loginModal"
    :class="{
      'show': modals.login
    }"
    tabindex="-1"
    aria-labelledby="loginLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-login">
      <Form
        @submit="onSubmit"
        @invalid-submit="onInvalidSubmit"
        :validation-schema="validationSchema"
        v-slot="{ isSubmitting }"
        :initial-values="initialValues"
        id="login-form"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="loginLabel">Member Login</h6>
            <button type="button" class="close" aria-label="Close" @click="closeModal('login')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p14">
            <BasicInput
              name="username"
              v-model="username"
              id="username"
              label="Username"
            />
            <BasicInput
              name="password"
              v-model="password"
              id="password"
              type="password"
              label="Password"
            />
            <div class="alert alert-danger mt-4" v-if="invalidSubmit">
              The form contains errors
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <div class="text-center">
              <button type="submit" class="btn btn-primary" style="min-height: 54px;" :disabled="isSubmitting">{{ !isSubmitting ? 'Login' : 'Loading...'}}</button>
              <div class="mt-2">
                <small><a to="#" class="text-primary">Forgot Username/Password</a></small>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  </div>

  <div
    class="modal fade"
    id="modalWelcome"
    :class="{
      'show': modals.welcome
    }"
    tabindex="-1"
    aria-labelledby="welcomeLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title" id="welcomeLabel">Begin building your Credit Profile now!</h6>
          <button type="button" class="close" aria-label="Close" @click="closeModal('welcome')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body p14">
          <div class="row align-items-center">
            <div class="col-md-5 mb-3 mb-md-0 order-md-2">
              <img src="images/intro-animation.gif" alt="Animation" class="img-fluid">
            </div>
            <div class="col-md-7">
              <span class="font-weight-bold">Please read:</span>
              <p class="mt-2">A Credit Builder Loan is designed to help you establish or boost your credit score. With a
                traditional loan, you receive funds upfront and make payments over time; with a Credit Builder Loan, you
                make payments into an interest-bearing saving account and receive the money back at the end of the loan
                period, or you may choose to receive them back monthly.</p>
              <p>We report your on-time payments to all three credit bureaus, which can help build a positive credit
                history. Consistent payments are more important than erratic large payments in building a credit
                history. If you need cash immediately, a traditional loan may be a better option for you.</p>
              <p>If you fully understand the Credit Builder Loan and would like to proceed, then</p>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-center">
          <button type="button" class="btn btn-primary" @click="closeModal('welcome')">Get Started</button>
        </div>
      </div>
    </div>
  </div>
  <div v-show="modals.backdrop" class="modal-backdrop fade"
    :class="{
      'show': modals.backdrop
    }"
  ></div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import BasicInput from './BasicInput';

export default {
  name: 'Modals',
  components: {
    BasicInput,
    Form,
  },
  data() {
    return {
      validationAttempt: false,
      saving: false,
      invalidSubmit: false,
      username: '',
      password: '',
      initialValues: { username: '', password: '' },
    };
  },
  methods: {
    ...mapActions(['registerAction', 'toggleModal']),
    closeModal(modal) {
      this.toggleModal({ modal, show: false });
    },
    onSubmit() {
      this.registerAction({
        name: 'loginFormSubmit',
        value: '1',
        type: 'submit',
      });
      this.closeModal('login');
    },
    onInvalidSubmit() {
      this.invalidSubmit = true;
      this.registerAction({
        name: 'loginFormSubmit',
        value: '0',
        type: 'submit',
      });
    },
  },
  computed: {
    ...mapGetters(['modals']),
    validationSchema() {
      return yup.object({
        username: yup.string().required('Please enter your username'),
        password: yup.string().required('Password is a required field'),
      });
    },
  },
}
</script>
