<template>
  <section id="hero">
    <div class="container">
      <div class="row align-items-center">
        <div class="hero-text col-md-5 mb-3 mb-md-0">
          <h1 class="mb-4">Boost your Credit Score, Empower your Future</h1>
          <h2 class="mb-4 mb-md-5"><strong>Boost your credit score</strong> by applying for our <strong>Credit Empowerment Loan.</strong>
          </h2>
          <div class="d-block d-md-none" style="height: 180px;"></div>          
          <Form
            @submit="onSubmit"
            @invalid-submit="onInvalidSubmit"
            :validation-schema="validationSchema"
            v-slot="{ isSubmitting }"
            :initial-values="initialValues"
            class="hero-form mb-4"
            id="hero-form"
          >
            <div class="hero-form-content">
              <Field name="email" v-model="email" v-slot="{ field, errors, errorMessage, meta }">
                <div class="d-flex flex-column">
                  <input
                    v-bind="field"
                    class="form-control"
                    id="email"
                    name="email"
                    type="email"
                    maxlength="255"
                    autocomplete="on"
                    placeholder="Enter your email & start building"
                    :class="[ ( !meta.valid && (meta.dirty && meta.touched) ) && 'form-control_invalid', ( meta.valid && meta.touched ) && 'form-control_valid' ]"
                  >
                  <div class="control-group__input-error pt-1">
                    <span v-if="!meta.valid && (meta.touched || meta.dirty)">
                      {{ errors[0] || errorMessage }}
                    </span>
                     <span class="d-none d-md-inline">&nbsp;</span>
                  </div>
                </div>
              </Field>
              
              <button type="submit" class="btn btn-primary" style="min-height: 54px;" :disabled="isSubmitting">{{ !isSubmitting ? 'Apply Now' : 'Loading...'}}</button>
            </div>
          </Form>
        </div>
        <div class="hero-image col-md-7 d-none d-md-block">
          <img src="images/hero-bg-b.png" alt="" class="img-fluid">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import { Field, Form } from 'vee-validate';
import * as yup from 'yup';

export default {
  name: 'HeroSection',
  components: {
    Field,
    Form,
  },
  data() {
    return {
      validationAttempt: false,
      saving: false,
      email: '',
      initialValues: { email: '' },
    };
  },
  methods: {
    ...mapActions(['registerAction', 'saveFormData']),
    onSubmit() {
      this.registerAction({
        name: 'homeFormSubmit',
        value: '1',
        type: 'submit',
      });
      this.saveFormData({
        email: this.email.toLowerCase(),
      });
      this.$router.push('create');
    },
    onInvalidSubmit() {
      this.registerAction({
        name: 'homeFormSubmit',
        value: '0',
        type: 'submit',
      });
    },
  },
  computed: {
    validationSchema() {
      return yup.object({
        email: yup.string().email('Email address is invalid').required('Please enter your email'),
      });
    },
  },
}
</script>
