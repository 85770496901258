import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { configure } from 'vee-validate';
import title from './mixins/title';


// Default values
configure({
  validateOnInput: true,
});


store.dispatch('getToken').then(()=>{
  const app = createApp(App).use(store).use(router)
  app.mixin(title);
  app.mount('#app');
})

