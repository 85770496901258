import { mapActions } from 'vuex';
import cookies from "@/helpers/cookie";

export default {
  data() {
    return {
      storageID: '',
      welcomeParam: '',
      subid1: '',
      subid2: '',
      subid3: '',
      subid4: '',
      subaccount: '',
      webmasterID: '',
    }
  },
  methods: {
    ...mapActions([
      'getUserFromStorage',
      'registerAction',
      'saveVisitData',
      'toggleModal',
    ]),
    async registerVisit() {
      const params = {
        subid1: this.subid1,
        subid2: this.subid2,
        subid3: this.subid3,
        subid4: this.subid4,
        webmasterID: this.webmasterID,
        subaccount: this.subaccount,
      };

      this.saveVisitData(params).then(() => {
        console.log('ready');
      });
    },
    async registerUser() {
      try {
        const response = await this.getUserFromStorage(this.storageID);
        if (response) {
          this.$router.push('apply');
        }
      } catch (err) {
        console.log(err);
      }
    },
    getURLParams() {
      let searchString = window.location.search;
      if (!window.location.search && window.location.hash) {
        searchString = window.location.hash.substring(window.location.hash.indexOf('?'));
      }
      const urlParams = new URLSearchParams(searchString);

      this.subid1 = urlParams.has('s1') ? urlParams.get('s1') : '';
      this.subid2 = urlParams.has('s2') ? urlParams.get('s2') : '';
      this.subid3 = urlParams.has('s3') ? urlParams.get('s3') : '';
      this.subid4 = urlParams.has('s4') ? urlParams.get('s4') : '';

      this.subaccount = urlParams.has('subacc') ? urlParams.get('subacc') : '';
      this.webmasterID = urlParams.has('aid') ? urlParams.get('aid') : '';

      this.storageID = urlParams.has('id') ? urlParams.get('id') : '';
      this.welcomeParam = urlParams.has('welcome') ? urlParams.get('welcome') : '';
    },
  },
  async mounted() {
    this.getURLParams();
    this.registerVisit();
    if(this.storageID) {
      this.registerUser();
    }
    this.registerAction({
      name: 'isStorageInclude',
      value: this.storageID ? '1' : '0',
      type: 'initDat',
    });

    if (!cookies.get('viewedWelcomeModal')) {
      this.toggleModal({ modal: 'welcome', show: true });
      cookies.set('viewedWelcomeModal', 'true', 30);
    }

    if (this.welcomeParam === '1') {
      this.toggleModal({ modal: 'welcome', show: true });
    }
  },
};
