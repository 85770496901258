import Api from '../../helpers/api';
import appStorage from '../../helpers/appStorage';

export default {
  saveFormData({commit}, data) {
    commit('saveFormData', data);
  },
  async getToken() {
    try {
      const response = await Api.post('token/', {
        themeCode: "embark.credit",
        type: "site",
        url: "stage.embark.credit",
      },
      {
      headers:{
        source: process.env.VUE_APP_AVAILABLE_SOURCE,
      }
    });

      Api.defaults.headers.common['Authorization'] = `Bearer ${response.data}`;
    } catch(err) {
      console.log('Issue getting token', err);
    }
  },
  async getUserFromStorage({ commit }, id) {
    if (id === '123') {
      // For Testing
      commit('saveUserFromStorage', {
        id,
        firstName: 'Test',
        lastName: 'Test',
        email: 'test@test.com',
        dob: '1980-12-01',
        zip: '90210',
        city: 'Beverly Hills',
        state: 'CA',
        address: '200 Test Street',
        cellPhone: '2012002222',
        ssn: '*****6789',
      });
      return true;
    }

    const endpoint = `storage/${id}`;
    try {
      const response = await Api.get(endpoint);
      if (response.data.storage) {
        commit('saveUserFromStorage', {
          id,
          ...response.data.storage
        });
        return true;
      }
      return false;
    } catch (err) {
      return false;
    }
  },
  registerAction(context, data) {
    return Api.post('action/', data)
      .catch((err) => {
      console.log(err);
    });
  },
  saveVisitData({commit, getters, state}, data) {
    commit('mergeVisitData', data);
    const visitData = getters.visitData;

    let sessionID = '';
    let sessionMatched = false;
    if (appStorage.getItem('session')) {
      const session = JSON.parse(appStorage.getItem('session'));
      if (session.sessionID !== '') {
        const now = new Date();
        if (now < session.expires) {
          // Session is valid, check for matches
          if (
            session.webmasterID === visitData.webmasterID
            && session.subaccount === visitData.subaccount
            && session.subid1 === visitData.subid1
            && session.subid2 === visitData.subid2
            && session.subid3 === visitData.subid3
            && session.subid4 === visitData.subid4
          ) {
            sessionMatched = true;
            sessionID = session.sessionID;
          } else {
            appStorage.removeItem('session');
          }
        } else {
          // Session has expired, remove it
          appStorage.removeItem('session');
        }
      }
    }

    const params = {
      ...visitData,
      sessionID,
      type: state.formType,
    };

    if (sessionMatched && sessionID) {
      Api.patch('click/', {sessionID})
        .then(() => {
          commit('saveSessionID', {...params});
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      Api.post('click/', params).then((response) => {
        commit('saveSessionID', {...params, sessionID: response.data.sessionID});
      }).catch((err) => {
        console.log(err);
      });
    }
  },
  async sendLeadCreditBuilder({state}) {
    //const disclosures = await dispatch('collectDisclosures');
    const params = {
      leadType: 'ourSite',
      webmasterID: state.visitData.webmasterID,
      sessionID: state.visitData.sessionID,
      subaccount: state.visitData.subaccount,
      subid1: state.visitData.subid1,
      subid2: state.visitData.subid2,
      subid3: state.visitData.subid3,
      subid4: state.visitData.subid4,
      firstName: state.formData.firstName,
      lastName: state.formData.lastName,
      email: state.formData.email,
      dob: reformatDate(state.formData.dob),
      zip: state.formData.zip,
      city: state.formData.city,
      state: state.formData.state,
      address: state.formData.address,
      cellPhone: state.formData.cellPhone,
      ssn: state.formData.ssn,
      tags: [],
      disclosures: [],
    }

    if (params.ssn.includes('*')) {
      delete params.ssn;
    }

    return submitLead(params)
      .then((response) => {
        const data = response.data;
        return {
          status: data.status || false,
          leadID: data.leadID,
        }
      });
  },
};

function submitLead(params, callsCount=0) {
  return new Promise((resolve, reject) => {
    Api.post(`credit-build/leads/`, params)
      .then(response => resolve(response))
      .catch(error => {
        if(callsCount < 3){
          submitLead(params, callsCount + 1)
            .then(response => resolve(response))
            .catch(e => reject(e))
        } else {
          reject(error);
        }
    })
  })
}

function reformatDate(dateString = '01/01/1970') {
  const formatted = dateString.match(/^(\d{2})\/(\d{2})\/(\d{4})$/);
  if (formatted) {
    return `${formatted[3]}-${formatted[1]}-${formatted[2]}`;
  }
  return '1970-01-01';
}