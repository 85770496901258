<template>
  <div class="home">
    <HeroSection />
    <section class="py-5 bg-grey">
    <div class="container">
      <div class="row justify-content-center text-center">
        <div class="col-lg-7">
          <h2 class="mb-4">Welcome to Embark</h2>
          <p class="lead mb-0">
            Together we will journey towards <strong>improving your credit profile</strong>. Whether you're
            looking to rebuild your credit, establish credit for the first time, or improve your credit profile,
            our <strong>Embark Credit Empowerment Loan</strong> (CEL) is suited for you.
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="py-5">
    <div class="container">
      <div class="text-center mb-5">
        <h2>Credit Empowerment Loan<br>
          <small class="txt-grey">How it works</small>
        </h2>
      </div>
      <div class="row">
        <div class="col-md-3 mb-5 hiw">
          <div class="hiw-list-icon"><img src="images/hiw-1.svg" alt="icon"></div>
          <div class="hiw-list-body">
            <div class="hiw-list-num">1</div>
            <div class="flex-grow-1">
              <div class="hiw-list-title">
                <h3>Qualify</h3>
              </div>
              <div class="hiw-list-description">Qualify for your loan</div>
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-5 hiw">
          <div class="hiw-list-icon"><img src="images/hiw-2.svg" alt="icon"></div>
          <div class="hiw-list-body">
            <div class="hiw-list-num">2</div>
            <div class="flex-grow-1">
              <div class="hiw-list-title">
                <h3>Repay</h3>
              </div>
              <div class="hiw-list-description">Timely pay it back</div>
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-5 hiw">
          <div class="hiw-list-icon"><img src="images/hiw-3.svg" alt="icon"></div>
          <div class="hiw-list-body">
            <div class="hiw-list-num">3</div>
            <div class="flex-grow-1">
              <div class="hiw-list-title">
                <h3>Impact</h3>
              </div>
              <div class="hiw-list-description">Positively impact your credit</div>
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-5 hiw">
          <div class="hiw-list-icon"><img src="images/hiw-4.svg" alt="icon"></div>
          <div class="hiw-list-body">
            <div class="hiw-list-num">4</div>
            <div class="flex-grow-1">
              <div class="hiw-list-title">
                <h3>Improve</h3>
              </div>
              <div class="hiw-list-description">Improve your chances of qualifying for loans</div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center d-flex flex-column d-sm-block">
        <router-link to="/create" class="btn btn-primary">Get Started</router-link> <router-link to="#learn-more" class="btn btn-link">Learn
          More</router-link>
      </div>
    </div>
  </section>
  <section class="py-5 bg-grey">
    <div class="container">
      <div class="row justify-content-center text-center">
        <div class="col-lg-7">
          <blockquote>
            <p class="lead mb-0">
              With Embark’s <strong>Credit Empowerment Loan,</strong> we work together to determine a
              loan that <strong>won’t put excess strains on your finances.</strong> Together, we Empower you.
            </p>
          </blockquote>
        </div>
      </div>
    </div>
  </section>
  <section class="py-5">
    <div class="container">
      <div class="text-center mb-5">
        <h2>Features</h2>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-5 mb-5">
          <div class="feature d-flex">
            <div>
              <img src="images/fi-1.svg" alt="icon" class="fi">
            </div>
            <div class="ml-3">
              <h3>No hard pull</h3>
              There is no hard pull on your credit when applying for a Self Credit Builder Account.
            </div>
          </div>
        </div>
        <div class="col-lg-5 mb-5">
          <div class="feature d-flex">
            <div>
              <img src="images/fi-2.svg" alt="icon" class="fi">
            </div>
            <div class="ml-3">
              <h3>Automate payments</h3>
              Autopay can help you stay on track without worrying about due dates.
            </div>
          </div>
        </div>
        <div class="col-lg-5 mb-5">
          <div class="feature d-flex">
            <div>
              <img src="images/fi-3.svg" alt="icon" class="fi">
            </div>
            <div class="ml-3">
              <h3>Track your credit score</h3>
              Watch how your score changes over time.
            </div>
          </div>
        </div>
        <div class="col-lg-5 mb-5">
          <div class="feature d-flex">
            <div>
              <img src="images/fi-4.svg" alt="icon" class="fi">
            </div>
            <div class="ml-3">
              <h3>Cancel anytime</h3>
              We know making payments isn’t always easy. That’s why you can cancel at any time and get your
              accumulated savings back.
            </div>
          </div>
        </div>
      </div>
      <div class="text-center d-flex d-sm-block flex-column">
        <router-link to="/create" class="btn btn-primary">Get Started</router-link>
      </div>
    </div>
  </section>
  <section id="learn-more" class="py-5 bg-grey fw-400">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-5 mb-5 mb-lg-0">
          <h2 class="mb-5 yl">Get ready to Embark with us on a stronger credit history. Simple. Affordable.
            Powerful.</h2>
          <img src="images/embark-girl-headphones.jpg" alt="Girl wearing headphones" class="subhero-img">
        </div>
        <div class="col-md-6 pt-lg-5">
          <h3 class="mb-4"><strong>Embark’s Credit Empowerment Loan: How does it work?</strong></h3>
          <p class="mb-4">1. Apply for a $1,000 bank-issued Embark Credit Empowerment Loan to build or establish
            your credit profile. Information provided by you will help us verify some basic personal
            information.</p>
          <p class="mb-4">2. We carry out a “soft pull” of your credit that will not affect your credit score</p>
          <p class="mb-4">3. Together, we will then determine the most affordable amount and term of your
            loan.</p>
          <p class="mb-4">4. The loan funds will be placed into a restricted account at the bank.</p>
          <p class="mb-4">5. As you begin to pay down your loan with on-time payments, we report this successful
            loan payment information to all 3 credit bureaus. Now you are beginning to make a positive impact on
            your credit score.</p>
          <p class="mb-5">6. Each time you make a payment, your funds go into your Embark Savings Account at the
            bank. You can choose to keep the principal amount of these payments in an interest-bearing savings
            account, or you may decide to withdraw those funds from your account 1 . You decide.</p>
          <div class="d-flex d-sm-block flex-column">
            <router-link to="/create" class="btn btn-primary">Get Started</router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="py-5" id="personal-loans">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-5 col-lg-4 mb-4 mb-md-0">
          <div class="card blue-border">
            <div class="card-body">
              <h5 class="strong text-center mb-4">Personal Credit Builder Loan</h5>
              <ul class="loan-feature-list">
                <li>$1,000 - $2,000 loan</li>
                <li>Help your personal credit profile with credit builder loan</li>
                <li>Manageable monthly payments</li>
                <li>Receive back your loan repayments each month or keep in a savings account – You Choose!</li>
                <li>With 3 on-time payments, you can take out a second loan</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-5 col-lg-4 mb-4 mb-sm-0">
          <div class="card blue-border">
            <div class="card-body">
              <h5 class="strong text-center mb-4">Business Credit Builder Loan</h5>
              <ul class="loan-feature-list">
                <li>$20,000 loan</li>
                <li>Help your business credit profile with a builder loan</li>
                <li>Manageable monthly payments</li>
                <li>Receive back your loan repayment, or keep in a savings account – You Choose!</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  </div>
</template>

<script>
import HeroSection from '@/components/HeroSection';

export default {
  name: 'Home',
  title: 'Boost your Credit Score',
  components: {
    HeroSection,
  },
}
</script>
