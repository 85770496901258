import { createStore } from 'vuex';
import data from './data';
import ui from './ui';

export default createStore({
  modules: {
    data,
    ui,
  }
})
