<template>
  <header id="top" :class="{ 'scrolling': scrolling }">
    <div class="container d-flex align-items-center">
      <div>
        <router-link to="/"><img src="/images/embark_logo.svg" alt="Embark.Credit" class="logo"></router-link>
      </div>
      <div class="top-menu d-none d-lg-flex" :class="$route.meta.applicationFlow ? 'ml-auto' : ''">
        <div><router-link to="/#personal-loans" class="scroll-trigger">Personal Loans</router-link></div>
        <div><router-link to="/process#start">The Process</router-link></div>
        <div><router-link to="/faq#start">FAQ</router-link></div>
        <div><router-link to="/about#start">About Us</router-link></div>
      </div>
      <div class="top-buttons" :class="$route.meta.applicationFlow ? 'd-block d-lg-none' : ''">
        <div class="nav-icon d-block d-lg-none mr-2 mr-md-0" @click="toggleMobileMenu" :class="{ open: showMobileMenu }">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <router-link  v-if="!$route.meta.applicationFlow" to="/create" class="btn btn-cta">Apply<span class="d-none d-sm-inline"> Now</span></router-link>
        <button v-if="!$route.meta.applicationFlow" class="btn btn-cta" data-toggle="modal" data-target="#loginModal" @click="openLogin">Login</button>
      </div>
    </div>
  </header>
  <MobileMenu v-if="showMobileMenu" @itemClicked="toggleMobileMenu" />
</template>

<script>
import { mapActions } from 'vuex';
import MobileMenu from './MobileMenu.vue';

export default {
  name: 'Header',
  components: {
    MobileMenu,
  },
  props: {
    scrolling: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showMobileMenu: false,
    }
  },
  methods: {
    ...mapActions(['toggleModal']),
    openLogin() {
      this.toggleModal({ modal: 'login', show: true });
    },
    toggleMobileMenu() {
      this.showMobileMenu = !this.showMobileMenu;
    }
  },
}
</script>
