<template>
  <footer>
    <div class="wrapper">
      <div class="footer-menu-section">
        <div class="row">
          <div class="col widget ">
            <h4>Accounts</h4>
            <ul class="menu">
              <li class=""><router-link to="/#personal-loans">Loan</router-link></li>
              <li><router-link to="/businessloans#start">Business Loan</router-link></li>
            </ul>
          </div>
          <div class="col widget">
            <h4>Company</h4>
            <ul class="menu">
              <li class=""><router-link to="/about#start">About Us</router-link></li>
              <li><router-link to="/faq">FAQs</router-link></li>
            </ul>
          </div>
          <div class="col widget">
            <h4><router-link to="/creditbasic#start">Credit Basics</router-link></h4>
            <ul class="menu">
            </ul>
          </div>
          <div class="col widget">
            <h4>Legal</h4>
            <ul class="menu">
              <li><a href="docs/terms-and-conditions.pdf" target="_blank">Terms and Conditions</a></li>
              <li><a href="docs/privacy-policy.pdf" target="_blank">Privacy Policy</a></li>
            </ul>
          </div>
          <div class="clear"></div>
        </div>
      </div>
      <div class="footer-block">
        <div class="footer-text">
          <p>To apply for an account with Embark Credit, you must be at least 18 years old, a citizen of the
            United States, or a permanent resident with a valid social security number, and have a valid bank
            account or debit card account for payment. All loan applications are subject to consumer report
            review and approval. It's important to note that Embark Credit is not a credit repair service and
            does not remove negative credit history from your credit profile. The Credit Builder Loan does not
            disburse cash to you,. The loan proceeds are placed into a secure bank account to which you do not
            have access. The payments you make to pay down your loan can be made available back to you, or if
            you choose, can stay in your savings account to earn interest.</p>
          <p>While we strive to help improve your credit profile, credit profile improvement is not guaranteed,
            and changes in your credit score reflect your unique financial behavior and history. Please also
            note that failure to make minimum required loan payments on time may result in your loan payment(s)
            being reported as delinquent to credit bureaus which may negatively impact your credit profile.</p>
          <p>We want to be transparent with our customers and let you know that individual results may vary.
            Testimonials from other individuals may not be representative of all individuals, and certain
            individuals may have inferior results than those indicated in testimonials.</p>
          <p>As of March 31, 2023, Embark Credit is a registered trademark of Embark Credit.</p>
        </div>
        <div class="copy-right">©2023 Embark Financial, LLC. All rights reserved</div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
}
</script>
