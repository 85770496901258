const state = () => ({
  modals: {
    backdrop: false,
    login: false,
    welcome: false,
  },
  steps: [],
  filledSteps: {},
  stepsData: {},
});

const getters = {
  modals: (state) => state.modals,
  steps: (state) => state.steps,
  filledSteps: (state) => state.filledSteps,
  stepsData: (state) => state.stepsData,
};

const actions = {
  toggleModal({commit, state}, data) {
    if (data.modal in state.modals) {
      commit('toggleModal', data);
    }
  },
};

const mutations = {
  toggleModal: (state, data) => {
    state.modals[data.modal] = data.show;
    state.modals.backdrop = data.show;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
