import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = () => ({
  formData: {},
  visitData: {
    subid1: '',
    subid2: '',
    subid3: '',
    subid4: '',
    sessionID: '',
    webmasterID: '',
    subaccount: '',
  },
  formType: 'ourSite',
});

export default {
  state,
  getters,
  actions,
  mutations,
};
