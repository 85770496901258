export const formatPhoneNumber = (phoneString = '') => {
  const formatted = phoneString.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (formatted) {
    return `${formatted[1]}-${formatted[2]}-${formatted[3]}`;
  }
  return '';
};

export const maskDOB = { date: true, delimiter: '/', datePattern: ['m', 'd', 'Y'] };
export const maskPhone = { numericOnly: true, delimiter: '-', blocks: [3, 3, 4] };
export const maskSSN = { numericOnly: true, delimiter: '-', blocks: [3, 2, 4] };
export const maskZip = { numericOnly: true, blocks: [5] };
export const maskState = { uppercase: true, blocks: [2] };
